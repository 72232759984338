<template>
  <div class="subscriptions mt-4">
    <v-row>
      <v-col
        class="hidden-sm-and-down"
        cols="0"
        md="3"
        v-if="showMenuNavigator"
      >
        <MenuNavigator class="menu-navigator" />
      </v-col>

      <v-col cols="12" sm="12" :md="showMenuNavigator ? '9' : '12'">
        <category-block
          v-if="!showMenuNavigator"
          :target="category"
          position="position1"
          class="category-block category-block-1"
        />
        <v-container class="pt-0 subscriptions">
          <div v-if="category" class="category-title">
            <h2
              v-if="!category.metaData.category_info.HIDE_TITLE"
              class="primary--text text--lighten-1 pa-3 pt-0 pl-0 pt-sm-3"
            >
              {{ title }}
            </h2>
            <div
              v-if="!category.metaData.category_info.HIDE_TITLE && subtitle"
              v-html="subtitle"
              class="default--text pb-3"
            ></div>
          </div>
          <v-row class="pa-4" no-gutters>
            <v-col
              cols="12"
              sm="3"
              md="3"
              class="d-flex flex-column text-center justify-center align-center h-100 px-2 pb-0 pt-4"
              v-for="(step, index) in Object.keys($t('subscriptions')).length"
              :key="index"
              :class="{
                'step-separator':
                  index < Object.keys($t('subscriptions')).length - 1
              }"
            >
              <div
                class="secondary default--text dot d-flex justify-center align-center"
              >
                <span
                  class="number"
                  v-html="$t('subscriptions.' + Number(index + 1) + '.number')"
                ></span>
              </div>
              <div>
                <strong
                  v-html="$t('subscriptions.' + Number(index + 1) + '.title')"
                ></strong>
              </div>
              <div>
                <p
                  class="stepper-description"
                  v-html="
                    $t('subscriptions.' + Number(index + 1) + '.description')
                  "
                ></p>
              </div>
            </v-col>
          </v-row>

          <h4 class="mb-3">
            {{ $t("subscriptionsModule.avaibilityDisclaimer") }}
          </h4>

          <v-row no-gutters>
            <v-col
              cols="12"
              md="12"
              order="1"
              order-md="0"
              class="cart-info pa-0"
            >
              <v-list
                id="subscriptionList"
                flat
                subheader
                dense
                class="cart-item-list checkout-item-list"
              >
                <v-list-item-group class="flex align-center" :key="renderKey">
                  <v-list-item
                    v-for="item in subscriptions"
                    :key="item.subscriptionPlanId"
                    class="px-0"
                  >
                    <div class="cart-item subscription pa-3">
                      <v-row align="center">
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          class="d-flex flex-md-row flex-sm-row flex-column align-center"
                        >
                          <img
                            v-if="item.product && item.product.mediaURL"
                            :src="item.product.mediaURL"
                            :alt="'Immagine prodotto'"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          :sm="!subscriptionActive(item) ? '4' : '7'"
                          :md="!subscriptionActive(item) ? '4' : '7'"
                          :lg="!subscriptionActive(item) ? '5' : '7'"
                          class="d-flex flex-md-row flex-sm-row flex-column align-center pl-1"
                        >
                          <div
                            class="description px-2 pt-3 px-sm-0 px-md-0 pt-sm-0 pt-md-0"
                          >
                            <div class="description text-wrap font-weight-bold">
                              {{ item.descr }}
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="2"
                          md="2"
                          class="d-flex flex-column"
                          v-if="
                            $vuetify.breakpoint.smAndUp &&
                              !subscriptionActive(item) &&
                              item.product
                          "
                        >
                          <ProductPrice
                            :product="item.product"
                            :showDescrWeight="false"
                            :showOldPrice="false"
                          ></ProductPrice>
                        </v-col>
                        <v-col
                          cols="8"
                          sm="2"
                          md="2"
                          class="d-flex flex-column"
                          v-if="subscriptionActive(item) && isExpired(item)"
                        >
                          <div
                            class="d-flex justify-center subscription-status expired"
                          >
                            <p>
                              <b>{{
                                $t("subscriptionsModule.expiredSubscription")
                              }}</b>
                            </p>
                          </div>
                          <div
                            class="d-flex justify-center subscription-expire"
                          >
                            <b
                              >{{ $t("subscriptionsModule.expired") }}
                              {{
                                $dayjs(item.renewalDate).format("D MMMM YYYY")
                              }}</b
                            >
                          </div>
                          <v-btn
                            medium
                            depressed
                            class="primary lighten-1 subs-btn expired-btn"
                            @click.stop.prevent="
                              addSubscriptionPlan(item.subscriptionPlanId)
                            "
                            :disabled="
                              subscriptionAdded(item.subscriptionPlanId) ||
                                !cart.homeDelivery
                            "
                          >
                            <span>
                              {{ $t("subscriptionsModule.renewSubscription") }}
                            </span>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="8"
                          sm="3"
                          md="3"
                          class="d-flex flex-column"
                          :class="{
                            'align-start':
                              subscriptionActive(item) &&
                              $vuetify.breakpoint.xsOnly
                          }"
                          v-else-if="
                            subscriptionActive(item) &&
                              !isExpiring(item) &&
                              !isExpired(item)
                          "
                        >
                          <div
                            class="d-flex justify-center subscription-status"
                          >
                            <p>
                              {{
                                $t("subscriptionsModule.activeSusbscription")
                              }}
                            </p>
                          </div>
                          <div
                            class="d-flex justify-center subscription-expire"
                          >
                            <b
                              >Scadenza
                              {{
                                $dayjs(item.renewalDate).format("D MMMM YYYY")
                              }}</b
                            >
                          </div>
                          <v-btn
                            medium
                            depressed
                            class="primary lighten-1 subs-btn"
                            @click.stop.prevent="
                              addSubscriptionPlan(item.subscriptionPlanId)
                            "
                            :disabled="
                              subscriptionAdded(item.subscriptionPlanId) ||
                                subscriptionAdded(item.subscriptionPlanId) ||
                                (subscriptionActive(item) && !isExpiring(item))
                            "
                          >
                            <span>
                              {{ $t("subscriptionsModule.renewSubscription") }}
                            </span>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="8"
                          sm="2"
                          md="2"
                          class="d-flex flex-column"
                          v-else-if="
                            subscriptionActive(item) && isExpiring(item)
                          "
                        >
                          <div
                            class="d-flex justify-center subscription-status expiring"
                          >
                            <p>
                              <b>{{
                                $t("subscriptionsModule.expiringSubscription")
                              }}</b>
                            </p>
                          </div>
                          <div
                            class="d-flex justify-center subscription-expire"
                          >
                            <b
                              >{{ $t("subscriptionsModule.expiring") }}
                              {{
                                $dayjs(item.renewalDate).format("D MMMM YYYY")
                              }}</b
                            >
                          </div>
                          <v-btn
                            medium
                            depressed
                            class="primary lighten-1 subs-btn expired-btn"
                            @click.stop.prevent="
                              addSubscriptionPlan(item.subscriptionPlanId)
                            "
                            :disabled="
                              subscriptionAdded(item.subscriptionPlanId) ||
                                !cart.homeDelivery
                            "
                          >
                            <span>
                              {{ $t("subscriptionsModule.renewSubscription") }}
                            </span>
                          </v-btn>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="3"
                          md="3"
                          class="d-flex flex-column"
                          v-else
                        >
                          <div
                            v-if="subscriptionOrdered(item)"
                            class="d-flex justify-center subscription-status"
                          >
                            <p>
                              {{
                                $t("subscriptionsModule.orderedSubscription")
                              }}
                            </p>
                          </div>
                          <v-btn
                            medium
                            depressed
                            class="primary lighten-1 subs-btn"
                            @click.stop.prevent="
                              addSubscriptionPlan(item.subscriptionPlanId)
                            "
                            :disabled="
                              subscriptionAdded(item.subscriptionPlanId) ||
                                !subscriptionPaid(item) ||
                                !cart.homeDelivery
                            "
                          >
                            <v-icon color="white" class="mr-2">$cart</v-icon>
                            <span>
                              {{ $t("subscriptionsModule.addToCart") }}
                            </span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-container>

        <v-spacer class="spacer-scroll"></v-spacer>

        <category-block
          :target="category"
          position="position2"
          class="category-block category-block-2"
        /> </v-col
    ></v-row>
  </div>
</template>
<style scoped lang="scss">
.subscriptions {
  .step-separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      border-right: 1px solid rgba(0, 0, 0, 0.25);
      border-bottom: none;
    }
  }
}
.dot {
  height: 50px;
  width: 50px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  .number {
    position: absolute;
    font-size: 20px;
    font-weight: 700;
  }
}
.stepper-description {
  font-size: 14px;
}
.cart {
  position: relative;
  padding-bottom: 56px;
}
.subscriptions {
  .category-title {
    .h2 {
      color: var(--v-primary-lighten1) !important;
    }
  }
}
.subscription {
  border: 2px solid var(--v-primary-lighten1) !important;
  border-radius: 6px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }
}
.subs-btn {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 0.675rem;
    padding: 0px 16px;
  }
}
.expired-btn {
  font-size: 0.675rem;
}
.subscription-status {
  p {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0px;
    color: #5bbe0d;
  }
}
.expiring {
  p {
    color: $secondary !important;
  }
}
.expired {
  p {
    color: #ff0000 !important;
  }
}
.subscription-expire {
  b {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0px;
  }
}
</style>
<script>
import RegistrationService from "~/service/userService";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import ProductPrice from "@/components/product/ProductPrice.vue";
// import ProductPromo from "@/components/product/ProductPromo.vue";
import catalogService from "@/commons/service/catalogService";

import { mapActions, mapState, mapGetters } from "vuex";
// import { forEachCartItem } from "~/service/ebsn";

import get from "lodash/get";

import eurospinCustomService from "@/service/eurospinCustomService";

import MenuNavigator from "@/components/profile/MenuNavigator";

export default {
  name: "Subscriptions",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  props: { showMenuNavigator: { type: Boolean, default: false } },
  components: {
    // CartItemList
    // CartItem
    // ProductPromo,
    ProductPrice,
    MenuNavigator
  },
  data() {
    return {
      dialogInstance: null,
      error: null,
      loading: false,
      giftCertificatesReceived: [],
      emailKey: 1,
      showAlternativesTooltip: false,
      renderKey: 1,
      subscriptions: [],
      userSubscriptions: []
    };
  },
  computed: {
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return get(c, "metaData.category_info.TITLE", c.name);
    },
    subtitle() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "I miei abbonamenti",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getCartStatus: "cart/getCartStatus"
    })
  },
  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      loadCart: "cart/loadCart",
      emptyCart: "cart/emptyCart",
      addGiftCode: "cart/addGiftCode",
      sendGiftCode: "cart/sendGiftCode",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async addUserGiftCode(giftCodeId) {
      let cart = await this.addGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async sendUserGiftCode(giftCodeId) {
      let cart = await this.sendGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async removeUserGiftCode(giftCodeId) {
      let cart = await this.removeGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async getUserDetails() {
      const data = await RegistrationService.getUserDetail();
      this.giftCertificatesReceived = data ? data.giftCertificatesReceived : [];
    },
    async getSubscriptionsList() {
      let subscriptions = await eurospinCustomService.subscriptionPlanList();
      this.subscriptions = subscriptions.subscriptionPlans;
    },
    async getUserSubscriptionsList() {
      let userSubscriptions = await eurospinCustomService.userSubscriptionPlanList();
      this.userSubscriptions = userSubscriptions.subscriptionPlans
        ? userSubscriptions.subscriptionPlans
        : [];
    },
    async addSubscriptionPlan(subscriptionPlan) {
      let params = {
        subscriptionPlanId: subscriptionPlan
      };
      let result = await eurospinCustomService.addSubscriptionPlan(params);
      if (
        (result.response && result.response.status == 0) ||
        result.status == 0
      ) {
        this.loadCart();
        // this.reload();
        this.subscriptions = result.data.subscriptionPlans;
      }
    },
    pricePerUnit(product) {
      return (
        product.price *
        parseFloat(
          Number(product.productInfos.WEIGHT_SELLING) /
            catalogService.getMultiplier(product)
        )
      );
    },
    subscriptionOrdered(subscription) {
      let isActive = false;
      let userSubscription = null;
      this.userSubscriptions.filter(ub => {
        if (ub.subscriptionPlanUsers) {
          return ub.subscriptionPlanUsers.filter(ss => {
            if (ub.subscriptionPlanId == subscription.subscriptionPlanId) {
              userSubscription = ss;
            }
          });
        }
      });
      if (
        userSubscription &&
        userSubscription.active == 1 &&
        userSubscription.subscriptionPlanUserStatus &&
        userSubscription.subscriptionPlanUserStatus
          .subscriptionPlanUserStatusId == 3 &&
        subscription.active == 1
      ) {
        isActive = true;
      }
      return isActive;
    },
    subscriptionActive(subscription) {
      let isActive = false;
      let userSubscription = null;
      this.userSubscriptions.filter(ub => {
        if (ub.subscriptionPlanUsers) {
          return ub.subscriptionPlanUsers.filter(ss => {
            if (ub.subscriptionPlanId == subscription.subscriptionPlanId) {
              userSubscription = ss;
            }
          });
        }
      });
      if (
        userSubscription &&
        userSubscription.active == 1 &&
        userSubscription.subscriptionPlanUserStatus &&
        userSubscription.subscriptionPlanUserStatus
          .subscriptionPlanUserStatusId == 8 &&
        subscription.active == 1
      ) {
        isActive = true;
      }
      return isActive;
    },
    subscriptionAdded(subscriptionPlanId) {
      let result = false;
      for (var i = 0; this.cart.cartItems.length > i; i++) {
        if (
          this.cart.cartItems[i].cartItemInfo.SUBSCRIPTION_PLAN_ID ==
          subscriptionPlanId
        ) {
          result = true;
          break;
        }
      }
      return result;
    },
    subscriptionPaid(subscription) {
      let subscriptionPaid = true;
      let userSubscription = null;
      this.userSubscriptions.filter(ub => {
        if (ub.subscriptionPlanUsers) {
          return ub.subscriptionPlanUsers.filter(ss => {
            if (ub.subscriptionPlanId == subscription.subscriptionPlanId) {
              userSubscription = ss;
            }
          });
        }
      });
      if (
        userSubscription &&
        userSubscription.active == 1 &&
        userSubscription.subscriptionPlanUserStatus &&
        userSubscription.subscriptionPlanUserStatus
          .subscriptionPlanUserStatusId == 3 &&
        subscription.active == 1
      ) {
        subscriptionPaid = false;
      }
      return subscriptionPaid;
    },
    isExpired(subscription) {
      let userSubscription = null;
      this.userSubscriptions.filter(ub => {
        return ub.subscriptionPlanUsers.filter(ss => {
          if (ub.subscriptionPlanId == subscription.subscriptionPlanId) {
            userSubscription = ss;
          }
        });
      });
      let result = false;
      /*let endDate = this.$dayjs(userSubscription.endDate).format("YYYY-MM-DD");*/
      // let today = this.$dayjs().format("YYYY-MM-DD");
      // endDate = "2024-06-10";
      /*let days = this.$dayjs(endDate).diff(this.$dayjs(today), "day");*/
      // let renewalDateEx = this.$dayjs(userSubscription.endDate).format(
      //   "YYYY-MM-DD"
      // );
      if (userSubscription.active === 1) {
        subscription.renewalDate = this.$dayjs(userSubscription.endDate).format(
          "YYYY-MM-DD"
        );
      }
      if (
        !userSubscription.active ||
        (userSubscription.active && userSubscription.active === 0) ||
        (userSubscription.active &&
          userSubscription.active === 1 &&
          this.$dayjs(userSubscription.endDate).format("YYYY-MM-DD") <
            this.$dayjs().format("YYYY-MM-DD"))
      ) {
        result = true;
      }
      return result;
    },
    isExpiring(subscription) {
      let userSubscription = null;
      this.userSubscriptions.filter(ub => {
        return ub.subscriptionPlanUsers.filter(ss => {
          if (ub.subscriptionPlanId == subscription.subscriptionPlanId) {
            userSubscription = ss;
          }
        });
      });
      let result = false;
      /*let endDate = this.$dayjs(userSubscription.endDate).format("YYYY-MM-DD");*/
      // let today = this.$dayjs().format("YYYY-MM-DD");
      // endDate = "2024-06-10";
      /*let days = this.$dayjs(endDate).diff(this.$dayjs(today), "day");*/
      // let renewalDate = this.$dayjs(userSubscription.endDate);
      if (userSubscription.active === 1) {
        subscription.renewalDate = this.$dayjs(userSubscription.endDate);
      }
      if (
        userSubscription.active === 1 &&
        this.$dayjs(userSubscription.endDate) &&
        subscription.renewalDaysBefore >=
          this.$dayjs(userSubscription.endDate).diff(
            this.$dayjs().format("YYYY-MM-DD"),
            "day"
          )
      ) {
        result = true;
      }
      return result;
    },
    async reload() {
      await this.getSubscriptionsList();
      await this.getUserSubscriptionsList();
    }
  },
  async mounted() {
    await this.reload();
  },
  created() {
    let vm = this;
    global.EventBus.$on("deleteSubscriptionPlan", subscriptionPlans => {
      vm.loadCart();
      this.subscriptions = subscriptionPlans;
      // vm.reload();
    });
  }
};
</script>
